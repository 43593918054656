import axios from "axios";
import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useParams,
} from "react-router-dom";
import "./App.css";
import AdminPage from "./Pages/AdminPage";
import ErrorPage from "./Pages/ErrorPage";
import Login from "./Pages/Login";
import N29Test from "./Pages/N-29_Test";
import LoadingModal from "./components/loadingModal";
import errorMsg from "./errorMsg.json";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/N29/:sessionId" element={<N29TestPage />} />
      </Routes>
    </Router>
  );
}

function N29TestPage() {
  const [loading, setLoading] = useState(false);
  const { sessionId } = useParams();
  const [test, setTest] = useState(null);
  const [err, setErr] = useState(null);
  const [running, setRunning] = useState(false);

  useEffect(() => {
    const dataFetch = async () => {
      setRunning(true);
      setLoading(true);
      await axios
        .get(`https://api.n29.bifo.at/testsessions/tests/${sessionId}`)
        .then((res) => {
          let data = res.data;
          setTest(data);
        })
        .catch(function (error) {
          if (error.response) {
            setErr(error.response.status);
          }
        })
        .finally(() => {
          setLoading(false);
          setRunning(false);
        });
    };
    if (!running && test === null && err === null) {
      dataFetch(sessionId);
    }
  }, [sessionId, test, running, err]);

  if (loading) {
    return <LoadingModal loading={loading} />;
  } else if (test !== null && test.sessionStatus === false) {
    return <N29Test test={test} />;
  } else if (err !== null) {
    return <ErrorPage error={errorMsg[err]} />;
  }
}

function Admin() {
  const [loading, setLoading] = useState(false);
  const [test, setTest] = useState(null);
  const [err, setErr] = useState(null);
  let running = false;
  let headers = {};

  const dataFetch = async () => {
    running = true;
    await axios
      .get(`https://api.n29.bifo.at/testsessions`, { headers: headers })
      .then((res) => {
        let data = res.data;
        setTest(data);
      })
      .catch(function (error) {
        if (error.response) {
          setErr(error.response.status);
        }
      })
      .finally(() => {
        running = false;
        setLoading(false);
      });
  };
  if (sessionStorage.token) {
    headers = { Authorization: sessionStorage.getItem("token") };
  }

  useEffect(() => {
    if (!running && test === null) {
      setLoading(true);
      dataFetch();
    }
    // eslint-disable-next-line
  }, []);
  if (loading) {
    return <LoadingModal loading={loading} />;
  } else if (err !== null) {
    return <ErrorPage error={errorMsg[err]} />;
  } else if (test !== null) {
    let newTest = [];
    for (let i = 0; i <= test.length - 1; i++) {
      if (test[i].sessionStatus) {
        newTest.push(test[i]);
      }
    }
    return <AdminPage Results={newTest} />;
  }
}

export default App;
