import React from "react";

const WordsEvaluationRow = (props) => {
  const goodWords = props.goodWords?.toString();
  const badWords = props.badWords?.toString();
  var backgroundColor = "white";

  if (props.index % 2 === 0) {
    backgroundColor = "#f1f1f1";
  } else {
    backgroundColor = "white";
  }

  return (
    <React.Fragment>
      <tr
        className={props.style.row}
        style={{ backgroundColor: backgroundColor }}
      >
        <td className={props.style.row}>{props.index + 1}</td>
        <td className={props.style.content}>
          {goodWords?.replaceAll(",", " | ")}
        </td>
        <td className={props.style.content}>
          {badWords?.replaceAll(",", " | ")}
        </td>
      </tr>
    </React.Fragment>
  );
};

export default WordsEvaluationRow;
