import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { MdOutlineContentCopy } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import css from "../../modules/Modal.module.css";

const CreateLinkModal = (props) => {
  const [toastQueue, setToastQueue] = useState(0);
  const copyToClipboard = () => {
    handleSuccesfulCopy();
    navigator.clipboard.writeText(props.createdLink);
  };

  const handleSuccesfulCopy = () => {
    toast.success("Link Kopiert!", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "colored",
      limit: 3,
    });
    setToastQueue(toastQueue + 1);
  };
  useEffect(() => {
    if (toastQueue >= 3) {
      toast.clearWaitingQueue();
    }
  });

  return (
    <Modal
      onHide={props.onHandleLinkState}
      show={props.linkState}
      centered
      size="lg"
    >
      <Modal.Body>
        <div className={css.content}>
          <ToastContainer
            limit={3}
            className={css.toast}
            closeOnClick={true}
          ></ToastContainer>
          <div className={css.Title}>{"Link wurde erfolgreich erstellt!"}</div>
          <p>
            Bitte kopieren Sie diesen Link und schicken Sie es an den Empfänger
          </p>
          <div>
            <a
              href={props.createdLink}
              className={css.createdLink}
              target={"_blank"}
              rel={"noreferrer"}
            >
              {props.createdLink}
            </a>

            <MdOutlineContentCopy
              className={css.clipBoard}
              onClick={copyToClipboard}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={css.modalFooter}>
          <Button
            className={css.buttonWidth2}
            variant={"outline-secondary"}
            onClick={props.handleGenerateNewLink}
          >
            {"Neuer Link"}
          </Button>
          <Button
            className={css.buttonWidth2}
            variant={"outline-primary"}
            onClick={props.onHandleLinkState}
          >
            {"Fertig"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default CreateLinkModal;
