import { React, useEffect, useState } from "react";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

const ListHeader = (props) => {
  const [sorted, setSorted] = useState(null);
  const sort = () => {
    props.event();
  };
  // eslint-disable-next-line
  useEffect(() => {
    if (props.isSorted) {
      if (props.sortedDesc) {
        setSorted(<TiArrowSortedDown />);
      } else {
        setSorted(<TiArrowSortedUp />);
      }
      props.onSorted();
    }

    if (props.sortedBy !== true) {
      setSorted();
    }
  });

  return (
    <div className={props.style} onClick={sort}>
      {props.name}
      {sorted}
    </div>
  );
};

export default ListHeader;
