import React, { useEffect, useState } from "react";

import CompletedTestBar from "./completedTestBar";
import Dud from "./dud";

const CompletedTestBarList = (props) => {
  const handlePdf = (selectionFragment, wordsFragment, name) => {
    props.onHandlePdf(selectionFragment, wordsFragment, name);
  };

  const handleDelete = (sessionId) => {
    props.onHandleDelete(sessionId);
  };

  const [results, setResults] = useState(props.results);
  const dudLength = props.numberOfTestsTo - results.length;

  // eslint-disable-next-line
  useEffect(() => {
    if (props.isSorted) {
      setResults(props.results);
      props.onSorted();
    }
  });

  return (
    <div>
      {results
        .slice(props.numberOfTestsFrom, props.numberOfTestsTo)
        .map((results, index) => (
          <CompletedTestBar
            index={index}
            onHandlePdf={handlePdf}
            onHandleDelete={handleDelete}
            result={results}
            key={index}
          ></CompletedTestBar>
        ))}
      {Array.from({ length: dudLength }).map((_, index) => (
        <Dud index={index + dudLength} key={index}></Dud>
      ))}
      <div></div>
    </div>
  );
};

export default CompletedTestBarList;
