import css from "../../modules/CompletedTestBar.module.css";

const Dud = (props) => {
  var Backgroundcolor = "transparent";
  if (props.index % 2 === 0) {
    Backgroundcolor = "white";
  }

  return (
    <div>
      <div
        className={css.main}
        style={{ backgroundColor: Backgroundcolor }}
      ></div>
    </div>
  );
};

export default Dud;
