import { Modal } from "react-bootstrap";
import loadingImg from "../assets/images/loading.gif";
import css from "../modules/Modal.module.css";

const LoadingModal = (props) => {
  return (
    <Modal show={props.loading} centered size="sm">
      <Modal.Body className={css.loading}>
        <img
          src={loadingImg}
          className={css.loadingImage}
          alt={"loading"}
        ></img>
        <div className={css.loadingMsg}>Lädt...</div>
      </Modal.Body>
    </Modal>
  );
};

export default LoadingModal;
