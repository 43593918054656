import { Button, Col, Modal, Row } from "react-bootstrap";
import css from "../../modules/Modal.module.css";

const IntroModal = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      props.onHandleNextModal();
    }
  };

  return (
    <Modal show={props.modalState} size={"lg"} backdrop={"static"} centered>
      <Modal.Header>
        <Modal.Title className={css.modalHeader}>
          <p className={css.introTitle}>Willkommen zum N29-Neigungstest</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={css.IntroInformation}>
          <p>
            Bei diesem Test werden auf den folgenden Seiten Ihre Interessen und
            Neigungen erfasst.
          </p>
          <p>Bitte geben Sie folgende Informationen an.</p>
        </div>
        <div onKeyDown={handleKeyDown}>
          <Row>
            <Col>
              <input
                type={"text"}
                name={"name"}
                onChange={props.onHandleNameChange}
                placeholder={"Max"}
                className={css.borderRadius}
                autoComplete={"off"}
              ></input>
              <p>{" Vorname "}</p>
            </Col>
            <Col>
              <input
                type={"text"}
                name={"familyName"}
                onChange={props.onHandleFamilyNameChange}
                placeholder={"Mustermann"}
                className={css.borderRadius}
                autoComplete={"off"}
              ></input>
              <p>{" Nachname "}</p>
            </Col>
            <Col>
              <input
                type={"number"}
                min={1}
                max={99}
                onChange={props.onHandleAgeChange}
                placeholder={"24"}
                className={css.borderRadius}
                autoComplete={"off"}
              ></input>
              <p>{" Alter "}</p>
            </Col>
          </Row>
          <div className={css.error}>
            <div hidden={props.error}>*Bitte richtige Werte eingeben*</div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={css.modalFooter}>
          <Button
            variant={props.buttonStyle}
            className={css.buttonWidth}
            onClick={props.onHandleNextModal}
            size={"lg"}
          >
            Weiter
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default IntroModal;
