import React from "react";
import css from "../../modules/WordsEvaluation.module.css";
import WordsEvaluationRow from "./wordsEvaluationRow";
const WordsEvaluation = (props) => {
  const words = props.wordsEvaluation;

  const pageTitle = // eslint-disable-next-line
    "Neigungstest N-29 " + "Name: " + props.name + " Alter: " + props.age;
  return (
    <div className={css.main}>
      <div className={css.heaven}>{pageTitle}</div>
      <div className={css.logo}></div>
      <div className={css.hell}>
        <div className={css.tableWrapper}>
          <table>
            <tbody>
              <tr className={css.upperHalf}>
                <td className={css.row}> {"Reihe"}</td>
                <td
                  className={css.headers}
                  style={{ backgroundColor: "#ee7f00" }}
                >
                  {"gern"}
                </td>
                <td
                  className={css.headers}
                  style={{ backgroundColor: "#086cb4" }}
                >
                  {"ungern"}
                </td>
              </tr>
            </tbody>
            <tbody>
              {Array.from({ length: 29 }).map((_, index) => (
                <WordsEvaluationRow
                  key={index}
                  index={index}
                  style={css}
                  goodWords={words[index]?.goodWords}
                  badWords={words[index]?.badWords}
                ></WordsEvaluationRow>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default WordsEvaluation;
