import { Modal } from "react-bootstrap";
import css from "../../modules/Modal.module.css";
const RotationNotification = (props) => {
  return (
    <Modal show={props.rotationState} centered className={css.RotationNoti}>
      <Modal.Header className={css.modalHeader}>
        <div className={css.Title}>{"Achtung!"}</div>
      </Modal.Header>
      <Modal.Body className={css.content}>Bitte das Gerät rotieren</Modal.Body>
    </Modal>
  );
};
export default RotationNotification;
