import { Button, Modal } from "react-bootstrap";
import css from "../../modules/Modal.module.css";
const DeleteWarningModal = (props) => {
  return (
    <Modal
      onHide={props.onHandleWarningState}
      show={props.warningState}
      centered
      size="xs
      "
    >
      <Modal.Body>
        <div className={css.content}>
          <div className={css.Title}>{"Achtung!"}</div>
          <p>
            Dieser Prozess ist unwiderruflich. Die Daten werden permanent
            gelöscht. Sind Sie sich Sicher, dass Sie diesen Test <b>LÖSCHEN</b>{" "}
            wollen?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={css.modalFooter}>
          <Button
            className={css.buttonWidth2}
            variant={"outline-primary"}
            size={"lg"}
            onClick={props.onHandleWarningState}
          >
            {"Eigentlich nicht"}
          </Button>
          <Button
            className={css.buttonWidth2}
            variant={"outline-danger"}
            size={"lg"}
            onClick={props.delete}
          >
            {"Ja"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default DeleteWarningModal;
