import React from "react";
import css from "../../modules/SelectionEvaluation.module.css";

import SelectionEvaluationCategory from "./selectionEvaluationCategory";
const SelectionEvaluation = (props) => {
  const evaluation = props.evaluation;
  const sliceLength = [0, 2, 4, 8, 12, 15, 18, 23, 29];

  return (
    <React.Fragment>
      <div className={css.main}>
        <div className={css.header}>
          {"Neigungstest N-29 " +
            "Name: " +
            props.name +
            " Alter: " +
            props.age}
          <div className={css.logo}></div>
        </div>
        <div className={css.tableWrapper}>
          <table className={css.tableContainer}>
            {Array.from({ length: sliceLength.length }).map((_, index) => (
              <SelectionEvaluationCategory
                key={index}
                evaluation={evaluation}
                sliceLengthFrom={sliceLength[index]}
                sliceLengthTo={sliceLength[index + 1]}
              />
            ))}
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SelectionEvaluation;
