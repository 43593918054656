const inputField = (props) => {
  return (
    <div>
      <input
        style={{ borderColor: props.underlineColor }}
        type={props.type}
        className={props.style}
        onChange={props.onHandleChange}
      ></input>
    </div>
  );
};

export default inputField;
