import { Button, Modal } from "react-bootstrap";

import css from "../../modules/Modal.module.css";

const SearchByIdModal = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      props.search();
    }
  };

  return (
    <Modal
      onHide={props.onHandleSearchModal}
      show={props.searchModal}
      centered
      size="lg"
    >
      <Modal.Body>
        <div className={css.searchBar}>
          <input
            className={css.searchInput}
            placeholder="Name"
            onChange={props.onHandleName}
            onKeyDown={handleKeyDown}
          ></input>
          <input
            className={css.searchInput}
            placeholder="Nachname"
            onChange={props.onHandleFamilyName}
            onKeyDown={handleKeyDown}
          ></input>
          <Button variant="primary" size="xm" onClick={props.search}>
            {"Suchen"}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SearchByIdModal;
