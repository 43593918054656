import css from "../modules/ErrorPage.module.css";

const ErrorPage = (props) => {
  const error = props.error;

  return (
    <div className={css.container}>
      <p className={css.errorNumber}>{error.type}</p>
      <p className={css.errorMessage}>{error.errorMessage}</p>
      <p className={css.errorSubMessage}>{error.errorSubMessage}</p>
    </div>
  );
};

export default ErrorPage;
