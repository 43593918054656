import React from "react";
import css from "../../modules/SelectionEvaluation.module.css";
import sentences from "../../sentences.json";
import EvaluationRow from "./evaluationRow";

const SelectionEvaluationCategory = (props) => {
  const evaluation = props.evaluation;

  return (
    <React.Fragment>
      <tbody className={css.group}>
        {sentences.sentences
          .slice(props.sliceLengthFrom, props.sliceLengthTo)
          .map((sentences, index) => (
            <EvaluationRow
              maxSliceLength={props.sliceLengthTo}
              Index={index}
              key={index}
              goodWords={evaluation[sentences.id - 1]?.good}
              badWords={evaluation[sentences.id - 1]?.bad}
              s={sentences.s}
              t={sentences.t}
              id={sentences.id}
            ></EvaluationRow>
          ))}
      </tbody>
    </React.Fragment>
  );
};

export default SelectionEvaluationCategory;
