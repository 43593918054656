import { Button, Modal } from "react-bootstrap";
import badWordsExample from "../../assets/instructionImages/bad_Words_Example.png";
import goodWordsExample from "../../assets/instructionImages/good_Words_Example.png";
import css from "../../modules/Modal.module.css";
const InfoModal = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      props.onHandleInfoModalState();
    }
  };

  return (
    <div onKeyDown={handleKeyDown}>
      <Modal
        onHide={props.onHandleInfoModalState}
        show={props.infoModalState}
        size={"lg"}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className={css.modalHeader}>
            <div className={css.Title}>{"Information"}</div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <p>
              Sie werden verschiedenen Tätigkeitsbegriffe sehen und Ihre Aufgabe
              sieht folgendermaßen aus:
            </p>
          </div>

          <div>
            <p>
              Bitte wählen Sie pro Seite <b>{"6 Begriffe"}</b>, die Sie
              interessieren (diese erscheinen <b>orange</b>)
            </p>
            <img
              className={css.image}
              src={goodWordsExample}
              alt={"good"}
            ></img>
          </div>
          <div>
            <p className={css.paragraph}>
              Wählen Sie auch <b>6 Begriffe</b> pro Seite, die Sie weniger gern
              machen (diese erscheinen <b>blau</b>).
            </p>
            <img className={css.image} src={badWordsExample} alt={"bad"}></img>
          </div>
          <div>
            <p className={css.paragraph}>
              Pro Seite müssen genau <b>12 Begriffe</b> gewählt sein, nicht mehr
              und nicht weniger.
            </p>
          </div>
          <div>
            <p className={css.paragraph}>
              Sollten Sie sich doch für einen anderen Begriff entscheiden, dann
              wählen Sie einfach einen Neuen aus. Entscheiden Sie einfach{" "}
              <b>spontan nach Gefühl</b> und überlegen Sie nicht zu lange. Die
              Ergebnisse kommen nach Abschluss des Tests direkt zu uns.
            </p>
          </div>
          <div>
            <h4 className={css.underline}>Gutes Gelingen!</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={css.buttonWidth}
            variant={"outline-primary"}
            size={"lg"}
            onClick={props.onHandleInfoModalState}
          >
            {"Schließen"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default InfoModal;
