import { renderToString } from "react-dom/server";
import { FaTrash } from "react-icons/fa";
import { ImDownload } from "react-icons/im";
import css from "../../modules/CompletedTestBar.module.css";
import CategoryEvaluation from "./selectionEvaluation";
import WordsEvaluation from "./wordsEvaluation";

const CompletedTestBar = (props) => {
  const tmpDate = new Date(props.result.date);
  const realDate =
    tmpDate.getDate().toString().padStart(2, "0") +
    "." +
    (tmpDate.getMonth() + 1).toString().padStart(2, "0") +
    "." +
    tmpDate.getFullYear().toString();

  const selectionFragment = renderToString(
    <CategoryEvaluation
      evaluation={props.result.evaluation}
      name={props.result.name}
      age={props.result.age}
    ></CategoryEvaluation>
  );

  const wordsFragment = renderToString(
    <WordsEvaluation
      wordsEvaluation={props.result.selectedWord}
      name={props.result.name}
      age={props.result.age}
    ></WordsEvaluation>
  );

  const handlePdf = () => {
    props.onHandlePdf(selectionFragment, wordsFragment, props.result.name);
  };

  const handleDelete = () => {
    props.onHandleDelete(props.result.sessionId);
  };

  var Backgroundcolor = "transparent";
  var Border;
  if (props.index % 2 === 0) {
    Backgroundcolor = "white";
  }

  if (props.index === 0) {
    Border = "none";
  }

  return (
    <div>
      <div
        className={css.main}
        style={{ backgroundColor: Backgroundcolor, border: Border }}
      >
        <div className={css.date}>{realDate}</div>
        <div className={css.name}>{props.result.name}</div>
        <div className={css.lastName}>{props.result.familyName}</div>
        <div className={css.print}>
          <ImDownload onClick={handlePdf} className={css.download} />
        </div>
        <FaTrash onClick={handleDelete} className={css.delete}></FaTrash>
      </div>
    </div>
  );
};

export default CompletedTestBar;
