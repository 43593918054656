import React, { useEffect, useState } from "react";
import { Flip, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FarewellModal from "../components/N29/farewellModal";
import InfoModal from "../components/N29/infoModal";
import InformationIcon from "../components/N29/informationIcon";
import RegisterModal from "../components/N29/registerModal";
import RotationNotification from "../components/N29/rotationNotification";
import WarningModal from "../components/N29/warningModal";
import WordSelectionList from "../components/N29/wordSelectionCheckBoxList";
import ButtonComp from "../components/button";
import "../cssReset.css";
import selectionArray from "../evaluation.json";
import css from "../modules/N29.module.css";
import wordsJson from "../n29_words.json";

function N29Test(props) {
  const [wordsMaxIndex, setWordsMaxIndex] = useState(29);
  const [wordsMinIndex, setWordsMinIndex] = useState(0);
  const [wordsCatalog, setWordsCatalog] = useState(wordsJson.words);
  const [numberOfChosenGoodWords, setNumberOfChosenGoodWords] = useState(0);
  const [numberOfChosenBadWords, setNumberOfChosenBadWords] = useState(0);
  const [goodSelection, setGoodSelection] = useState(false);
  const [badSelection, setBadSelection] = useState(false);
  const [evaluation, setEvalutation] = useState(selectionArray.evaluation);
  const [evaluationWords, setEvalutationWords] = useState(
    selectionArray.selectedWord
  );
  const [baseInformation, setBaseInformation] = useState(selectionArray);
  const [disabled, setDisabled] = useState(false);
  const [modalState, setModalState] = useState(true);
  const [infoModalState, setInfoModalState] = useState(false);
  const [name, setName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [age, setAge] = useState(0);
  const [error, setError] = useState(true);
  const [buttonStyle, setButtonStyle] = useState("outline-primary");
  const [modalNextPage, setModalNextPageState] = useState(false);
  const [modalFarewell, setModalFarewell] = useState(false);
  const [finishButton, setFinishButton] = useState("Weiter");
  const [toastQueue, setToastQueue] = useState(0);
  const [rotationState, setRotationState] = useState(false);

  //-
  //---
  //-----
  //* modal states
  //-----
  //---
  //-

  const handleModalState = () => {
    setModalState(() => false);
  };

  const handleInfoModalState = () => {
    if (infoModalState === false) {
      setInfoModalState(true);
    } else {
      setInfoModalState(() => false);
    }
  };

  const handleModalNextPageState = () => {
    if (modalNextPage === false) {
      setModalNextPageState(true);
    } else {
      setModalNextPageState(() => false);
    }
  };

  const handleNextModal = () => {
    if (name === "" || familyName === "" || age === 0 || age >= 100) {
      falseInput();
    } else if (
      !name.replace(/\s/g, "").length ||
      !familyName.replace(/\s/g, "").length
    ) {
      falseInput();
    } else if (/\d/.test(name) || /\d/.test(familyName)) {
      falseInput();
    } else {
      let currentDate = new Date();
      setBaseInformation((baseInformation.date = currentDate));
      setBaseInformation((baseInformation.name = name.trim()));
      setBaseInformation((baseInformation.familyName = familyName.trim()));
      setBaseInformation((baseInformation.age = age));
      handleModalState();
      setInfoModalState(true);
    }
  };

  //-
  //---
  //-----
  //* registration
  //-----
  //---
  //-

  const changedInput = () => {
    setError(true);
    setButtonStyle("outline-primary");
  };

  const falseInput = () => {
    setError(false);
    setButtonStyle("outline-danger");
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    changedInput();
  };

  const handleFamilyNameChange = (event) => {
    setFamilyName(event.target.value);
    changedInput();
  };

  const handleAgeChange = (event) => {
    setAge(event.target.value);
    changedInput();
  };

  //-
  //---
  //-----
  //* toast
  //-----
  //---
  //-

  const handleTooManyWords = (word) => {
    toast.error("Maximal" + word, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "colored",
      limit: 3,
      transition: Flip,
    });
    setToastQueue(toastQueue + 1);
  };

  //-
  //---
  //-----
  //* test execution
  //-----
  //---
  //-

  const handleCheckAuswahl = (value, Int) => {
    let newWordsArray = [...wordsCatalog];
    let Index = newWordsArray.indexOf(value);
    newWordsArray[Index] = { ...value };
    if (Int === 1) {
      newWordsArray[Index].selection = 1;
    } else if (Int === 2) {
      newWordsArray[Index].selection = 2;
    }
    setWordsCatalog(() => newWordsArray);
  };

  const handleCheckAuswahlNeutral = (value, Int) => {
    let newWordsArray = [...wordsCatalog];
    let Index = newWordsArray.indexOf(value);
    newWordsArray[Index] = { ...value };
    if (Int === newWordsArray[Index].selection) {
      newWordsArray[Index].selection = 0;
      setWordsCatalog(() => newWordsArray);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    let chosenGoodSelections = 0;
    let chosenBadSelections = 0;
    for (let i = wordsMinIndex; i <= wordsMaxIndex - 1; i++) {
      if (wordsCatalog[i].selection === 1) {
        chosenGoodSelections++;
      } else if (wordsCatalog[i].selection === 2) {
        chosenBadSelections++;
      }
    }

    setNumberOfChosenGoodWords(chosenGoodSelections);
    setNumberOfChosenBadWords(chosenBadSelections);
    checkBoolean();

    if (toastQueue >= 3) {
      toast.clearWaitingQueue();
    }
    function handleResize() {
      if (window.innerHeight >= 450) {
        setRotationState(false);
      } else {
        setRotationState(true);
      }
    }

    window.addEventListener("resize", handleResize);
  });

  const checkBoolean = () => {
    if (numberOfChosenGoodWords === 6) {
      setGoodSelection(true);
    } else {
      setGoodSelection(false);
    }
    if (numberOfChosenBadWords === 6) {
      setBadSelection(true);
    } else {
      setBadSelection(false);
    }

    if (numberOfChosenGoodWords === 6 && numberOfChosenBadWords === 6) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleNextPage = () => {
    if (wordsMaxIndex <= 347) {
      setModalNextPageState(false);
      let newArray = wordsMaxIndex + 29;
      setWordsMinIndex(wordsMaxIndex);
      setWordsMaxIndex(newArray);
      window.scrollTo({ top: 0, behavior: "smooth" });

      if (newArray >= 347) {
        setFinishButton("Abgeben");
      }
      handleModalNextPageState();
    } else {
      handleModalNextPageState();
      setModalFarewell(true);
      evaluationProcess();
    }
  };

  function PushTest() {
    let sessionId = props.test.sessionId;

    fetch(`https://api.n29.bifo.at/testsessions/tests/${sessionId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selectionArray),
    })
      .then((response) => response.json())
      .catch((error) => console.error(error));
  }

  //-
  //---
  //-----
  //* test evaluation
  //-----
  //---
  //-

  const evaluationProcess = () => {
    let sessionId = props.test.sessionId;
    fetch(`https://api.n29.bifo.at/testsessions/tests/${sessionId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
    });

    let bracketsLocation = [
      20, 50, 58, 110, 141, 166, 200, 203, 255, 261, 314, 319,
    ];
    let newWordEvaluation = [...evaluationWords];
    let newEvaluation = [...evaluation];
    let goodSelections = 0;
    let badSelections = 0;
    let newGoodWords = [];
    let newBadWords = [];

    for (let evaluationRow = 0; evaluationRow <= 28; evaluationRow++) {
      for (
        let evaluationColumn = 0;
        evaluationColumn <= 11;
        evaluationColumn++
      ) {
        let bracket = bracketsLocation[evaluationColumn];
        if (wordsCatalog[bracket].selection === 1) {
          goodSelections++;
          newGoodWords.push(wordsCatalog[bracket].word);
        } else if (wordsCatalog[bracket].selection === 2) {
          badSelections++;
          newBadWords.push(wordsCatalog[bracket].word);
        }
      }
      newEvaluation[evaluationRow].good = goodSelections;
      newEvaluation[evaluationRow].bad = badSelections;
      setEvalutation(() => newEvaluation);
      goodSelections = 0;
      badSelections = 0;

      newWordEvaluation[evaluationRow].goodWords = newGoodWords;
      newWordEvaluation[evaluationRow].badWords = newBadWords;
      setEvalutationWords(() => newWordEvaluation);
      newGoodWords = [];
      newBadWords = [];

      //-
      //---
      //-----
      //* update the Brackets
      //-----
      //---
      //-

      for (let bracketColumn = 0; bracketColumn <= 11; bracketColumn++) {
        bracketsLocation[bracketColumn] += 1;
        let scannerColumn = bracketsLocation[bracketColumn];
        switch (scannerColumn) {
          case 29:
            bracketsLocation[bracketColumn] = 0;
            break;
          case 58:
            bracketsLocation[bracketColumn] = 29;
            break;
          case 87:
            bracketsLocation[bracketColumn] = 58;
            break;
          case 116:
            bracketsLocation[bracketColumn] = 87;
            break;
          case 145:
            bracketsLocation[bracketColumn] = 116;
            break;
          case 174:
            bracketsLocation[bracketColumn] = 145;
            break;
          case 203:
            bracketsLocation[bracketColumn] = 174;
            break;
          case 232:
            bracketsLocation[bracketColumn] = 203;
            break;
          case 261:
            bracketsLocation[bracketColumn] = 232;
            break;
          case 290:
            bracketsLocation[bracketColumn] = 261;
            break;
          case 319:
            bracketsLocation[bracketColumn] = 290;
            break;
          case 348:
            bracketsLocation[bracketColumn] = 319;
            break;
          default:
        }
      }
    }
    PushTest();
  };

  return (
    <React.Fragment>
      <div className={css.Container}>
        <div>
          <RegisterModal
            error={error}
            modalState={modalState}
            onHandleModalState={handleModalState}
            onHandleNameChange={handleNameChange}
            onHandleAgeChange={handleAgeChange}
            onHandleNextModal={handleNextModal}
            onHandleFamilyNameChange={handleFamilyNameChange}
            buttonStyle={buttonStyle}
          ></RegisterModal>
          <RotationNotification rotationState={rotationState} />
          <InfoModal
            infoModalState={infoModalState}
            onHandleInfoModalState={handleInfoModalState}
          ></InfoModal>
          <WarningModal
            modalNextPage={modalNextPage}
            onHandleNextPage={handleNextPage}
            onHandleModalNextPageState={handleModalNextPageState}
          ></WarningModal>
          <FarewellModal modalFarewell={modalFarewell}></FarewellModal>
        </div>
        <div className={css.Header}>
          <div className={css.Title}>BIFO N-29 Neigungstest</div>
          <div className={css.Information}>
            <InformationIcon event={handleInfoModalState} />
          </div>
        </div>
        <div>
          <ToastContainer
            limit={3}
            className={css.toast}
            closeOnClick={true}
          ></ToastContainer>
        </div>
        <div className={css.main}>
          <div className={css.WordList}>
            <div className={css.TestHeader}>
              <div className={css.TestHeaderWordPositiv}>gern</div>
              <div className={css.WordHeader}>Wörter</div>
              <div className={css.TestHeaderWordNegativ}>ungern</div>
            </div>
            <div className={css.WordSelectionList}>
              <WordSelectionList
                words={wordsCatalog} //* prop: das ganze array wird als prop weiter gegeben
                maxArray={wordsMaxIndex}
                minArray={wordsMinIndex}
                badSelection={badSelection}
                goodSelection={goodSelection}
                chosenBadSelection={numberOfChosenBadWords}
                chosenGoodSelection={numberOfChosenGoodWords}
                onCheckAuswahl={handleCheckAuswahl}
                onhandleTooManyWords={handleTooManyWords}
                onCheckAuswahlNeutral={handleCheckAuswahlNeutral}
              ></WordSelectionList>
            </div>
          </div>
          <div className={css.Logo}>
            <div className={css.LogoPicture}></div>
            <div className={css.NextButton}>
              <ButtonComp
                style={css.button}
                name={finishButton}
                disabled={disabled}
                event={handleModalNextPageState}
              ></ButtonComp>
            </div>
            <div className={css.wordsSelection}>
              <div className={css.goodWords}>
                <p>{numberOfChosenGoodWords + " gern"}</p>
              </div>
              <div className={css.badWords}>
                <p>{numberOfChosenBadWords + " ungern"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default N29Test;
