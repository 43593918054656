import { Modal } from "react-bootstrap";
import css from "../../modules/Modal.module.css";

const FarewellModal = (props) => {
  return (
    <Modal show={props.modalFarewell} centered>
      <Modal.Header className={css.modalHeader}>
        <div className={css.Title}>{"Glückwunsch"}</div>{" "}
      </Modal.Header>
      <Modal.Body>
        <div className={css.paragraph}>Sie haben den Test abgeschlossen!</div>
        <div>Vielen Dank, Sie können die Seite nun schließen!</div>
      </Modal.Body>
    </Modal>
  );
};

export default FarewellModal;
