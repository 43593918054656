import { Button, Modal } from "react-bootstrap";
import css from "../../modules/Modal.module.css";
const WarningModal = (props) => {
  return (
    <Modal
      onHide={props.onHandleModalNextPageState}
      show={props.modalNextPage}
      centered
    >
      <Modal.Header className={css.modalHeader}>
        <div className={css.Title}>{"Achtung!"}</div>
      </Modal.Header>
      <Modal.Body>
        <div>
          Wenn Sie auf <b> Weiter</b> drücken können Sie Ihre Auswahl nicht mehr
          ändern
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={css.modalFooter}>
          <Button
            className={css.buttonWidth2}
            variant={"outline-danger"}
            size={"lg"}
            onClick={props.onHandleModalNextPageState}
          >
            {"Zurück"}
          </Button>
          <Button
            className={css.buttonWidth2}
            variant={"outline-primary"}
            size={"lg"}
            onClick={props.onHandleNextPage}
          >
            {"Weiter"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default WarningModal;
