import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonComp from "../components/button";
import InputField from "../components/inputField";
import css from "../modules/Login.module.css";
function Login() {
  const navigate = useNavigate();
  const Login = () => {
    TrueLogin(username, password, navigate);
  };

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [underlineColor, setUnderlineColor] = useState("black");

  const resetError = () => {
    setErr(true);
    setUnderlineColor("black");
  };
  const handleChangeUsername = (event) => {
    setUserName(event.target.value);
    resetError();
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
    resetError();
  };

  async function TrueLogin(username, password, navigate) {
    await axios
      .post("https://api.n29.bifo.at/auth/login", {
        username: username,
        password: password,
      })
      .then((res) => {
        window.sessionStorage.setItem("token", res.data.token);
        if (res.status === 200) {
          navigate("/admin");
        }
      })
      .catch((err) => {
        setErrMsg(err.response.data.message);
        setErr(false);
        setUnderlineColor("red");
      });
  }

  useEffect(() => {});

  return (
    <React.Fragment>
      <main className={css.Container}>
        <div className={css.Background}>
          <div className={css.Logo}></div>
          <div className={css.Username}>
            <p>Username</p>
            <InputField
              id={css.UserInput}
              style={css.inputField}
              underlineColor={underlineColor}
              onHandleChange={handleChangeUsername}
            ></InputField>
          </div>
          <div className={css.Passwort}>
            <p>Passwort</p>
            <InputField
              type="password"
              style={css.inputField}
              onHandleChange={handleChangePassword}
              underlineColor={underlineColor}
            ></InputField>
          </div>
          <div className={css.errMsg} hidden={err}>
            {errMsg}
          </div>
          <ButtonComp
            name={"Login"}
            style={css.Butt}
            event={Login}
          ></ButtonComp>
        </div>
      </main>
    </React.Fragment>
  );
}

export default Login;
