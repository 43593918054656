import axios from "axios";
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";
import React, { useEffect, useState } from "react";
import { GoX } from "react-icons/go";
import { ImSearch } from "react-icons/im";
import { IoLogOutSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CompletedTestBarList from "../components/AdminPage/completedTestBarList";
import CreateLinkModal from "../components/AdminPage/createLinkModal";
import DeleteWarningModal from "../components/AdminPage/deleteWarningModal";
import ListHeader from "../components/AdminPage/listHeader";
import SearchByIdModal from "../components/AdminPage/searchByIdModal";
import Button from "../components/button";
import LoadingModal from "../components/loadingModal";
import css from "../modules/AdminPage.module.css";

// eslint-disable-next-line

function AdminPage(props) {
  //-
  //---
  //-----
  //* react router
  //-----
  //---
  //-

  const navigate = useNavigate();
  const logout = async () => {
    try {
      let headers = {};
      if (sessionStorage.token) {
        headers = { Authorization: sessionStorage.getItem("token") };
      }
      await axios
        .post("https://api.n29.bifo.at/auth/revokeToken", {
          body: headers,
        })
        .then(window.sessionStorage.clear(), navigate("/login"));
    } catch (err) {
      console.error(err.response);
    }
  };

  const Results = props.Results;
  const Tests = props.Results;
  const [initSort, setInitSort] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [isSorted, setIsSorted] = useState(false);
  const [searched, setSearched] = useState(false);
  const [descending, setDescending] = useState(true);
  const [searchModal, setSearchModal] = useState(false);
  const [testResults, setTestResults] = useState(Tests);
  const [sortedByDate, setSortedByDate] = useState(true);
  const [searchByName, setSearchByName] = useState("");
  const [warningState, setWarningState] = useState(false);
  const [linkState, setLinkState] = useState(false);
  const [deleteSessionId, setDeleteSessionId] = useState("");
  const [searchByFamilyName, setSearchByFamilyName] = useState("");
  const [toastQueue, setToastQueue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [createdLink, setCreatedLink] = useState(null);
  const [searchSuccess, setSearchSuccess] = useState(false);
  const [sortedByName, setsortedByName] = useState(false);
  const [displayedTestsMax, setDisplayedTestsMax] = useState(16);
  const [displayedTestsMin, setDisplayedTestsMin] = useState(0);
  const [sortedByFamilyName, setsortedByFamilyName] = useState(false);

  //-
  //---
  //-----
  //* PDF creation
  //-----
  //---
  //-

  const HTMLselectionEvaluation = document.createElement("div");
  const HTMLwordsEvaluation = document.createElement("div");
  const createPdf = (selectionFragment, wordsFragment, name) => {
    let doc = new jsPDF({ orientation: "landscape" }, "mm", "a4", true);
    HTMLselectionEvaluation.innerHTML = selectionFragment;
    document.body.appendChild(HTMLselectionEvaluation);
    domtoimage.toPng(HTMLselectionEvaluation).then(function (dataurl) {
      let selectionImg = new Image();
      selectionImg.src = dataurl;
      selectionImg.onload = () => {
        doc.setDocumentProperties({
          title: "Neigungstest von " + name,
          author: "bifo",
          subject: "sunb",
        });

        doc.addImage(selectionImg, "JPEG", 0, 0, 0, 0, "", "FAST");
        HTMLwordsEvaluation.innerHTML = wordsFragment;
        document.body.appendChild(HTMLwordsEvaluation);
        domtoimage.toPng(HTMLwordsEvaluation).then(function (dataUrl) {
          let wordImg = new Image();
          wordImg.src = dataUrl;
          doc.addPage();
          doc.addImage(wordImg, "JPEG", 0, 0, 0, 0, "", "FAST");
          doc.output("dataurlnewwindow", "Neigungstest Auswertung");
          document.body.removeChild(HTMLwordsEvaluation);
          document.body.removeChild(HTMLselectionEvaluation);
        });
      };
    });
  };

  //-
  //---
  //-----
  //* sorting methods
  //-----
  //---
  //-

  const onHandleSortedDesc = () => {
    setDescending(false);
  };

  const hanldeOnSorted = () => {
    setIsSorted(false);
  };

  const sortByDate = () => {
    let sorted = testResults.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
    setDescending(false);
    changeList(sorted);
  };

  const sortByName = () => {
    let sorted = testResults.sort((a, b) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
    setDescending(true);
    changeList(sorted);
  };

  const sortByFamilyName = () => {
    let sorted = testResults.sort((a, b) => {
      return a.familyName
        .toLowerCase()
        .localeCompare(b.familyName.toLowerCase());
    });
    setDescending(true);
    changeList(sorted);
  };

  const resultsSortedByDate = () => {
    if (sortedByDate === false) {
      setSortedByDate(true);
      setsortedByName(false);
      setsortedByFamilyName(false);
      sortByDate();
    } else {
      if (descending) {
        sortByDate();
      } else {
        let sorted = testResults.sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });
        setDescending(true);
        changeList(sorted);
      }
    }
  };
  //eslint-disable-next-line
  const resultsSortedByName = () => {
    if (sortedByName === false) {
      sortByName();
      setsortedByName(true);
      setSortedByDate(false);
      setsortedByFamilyName(false);
    } else {
      if (!descending) {
        sortByName();
      } else {
        let sorted = testResults.sort((a, b) => {
          return b.name.toLowerCase().localeCompare(a.name.toLowerCase());
        });
        setDescending(false);
        changeList(sorted);
      }
    }
  };

  const resultsSortedByFamilyName = () => {
    if (sortedByFamilyName === false) {
      setsortedByFamilyName(true);
      setSortedByDate(false);
      setsortedByName(false);
      sortByFamilyName();
    } else {
      if (!descending) {
        sortByFamilyName();
      } else {
        let sorted = testResults.sort((a, b) => {
          return b.familyName
            .toLowerCase()
            .localeCompare(a.familyName.toLowerCase());
        });
        setDescending(false);
        changeList(sorted);
      }
    }
  };

  //-
  //---
  //-----
  //* inital Sort
  //-----
  //---
  //-

  const changeList = (newList) => {
    let sort = testResults;
    sort.tests = newList;
    setTestResults(sort);
    setIsSorted(true);
  };

  if (initSort) {
    sortByDate();
    setInitSort(false);
  }

  //-
  //---
  //-----
  //* switch Pages
  //-----
  //---
  //-

  const nextPageOfTest = () => {
    if (displayedTestsMax < testResults.length) {
      let placeholderFrom = displayedTestsMin + 16;
      let placeholderTo = displayedTestsMax + 16;
      setDisplayedTestsMin(placeholderFrom);
      setDisplayedTestsMax(placeholderTo);
      setPageNumber(() => pageNumber + 1);
    }
  };

  const lastPageOfTest = () => {
    if (displayedTestsMin !== 0) {
      let placeholderFrom = displayedTestsMin - 16;
      let placeholderTo = displayedTestsMax - 16;
      setDisplayedTestsMin(placeholderFrom);
      setDisplayedTestsMax(placeholderTo);
      setPageNumber(() => pageNumber - 1);
    }
  };

  //-
  //---
  //-----
  //* create Link Method
  //-----
  //---
  //-

  const generateNewLink = () => {
    setLoading(true);
    axios
      .post(`https://api.n29.bifo.at/testsessions/generate-link`)
      .then((res) => {
        let link = res.data.link;
        setCreatedLink(link);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generateLink = () => {
    if (createdLink === null) {
      generateNewLink();
    }
    setLinkState(true);
  };

  const handleLinkState = () => {
    if (!linkState) {
      setLinkState(true);
    } else {
      setLinkState(false);
    }
  };

  //-
  //---
  //-----
  //* delete Method
  //-----
  //---
  //-

  const handleWarningState = () => {
    if (!warningState) {
      setWarningState(true);
    } else {
      setWarningState(false);
    }
  };

  const deleteRequest = (sessionId) => {
    handleWarningState();
    setDeleteSessionId(sessionId);
  };

  const deleteAcknowledge = () => {
    handleWarningState();
    axios.delete(
      `https://api.n29.bifo.at/testsessions/tests/${deleteSessionId}`
    );
    window.location.reload();
  };

  //-
  //---
  //-----
  //* search Method
  //-----
  //---
  //-

  const handleSearchModal = () => {
    if (searchModal === true) {
      setSearchByFamilyName("");
      setSearchByName("");
      setSearchModal(false);
    } else {
      setSearchModal(true);
    }
  };

  const handleNameInput = (event) => {
    setSearchByName(event.target.value.trim());
    setTestResults(Tests);
  };

  const handleFamilyNameInput = (event) => {
    setSearchByFamilyName(event.target.value.trim());
    setTestResults(Tests);
  };

  const handleResetFilter = () => {
    setTestResults(Tests);
    setIsSorted(true);
  };

  const search = () => {
    let newResults = Results;
    let oldResults = Tests;
    let namePlaceholder = oldResults.filter(
      (test) => test.name.toLowerCase() === searchByName.toLowerCase()
    );
    let familyNamePlaceholder = oldResults.filter(
      (test) =>
        test.familyName.toLowerCase() === searchByFamilyName.toLowerCase()
    );

    let nameAndFamilyNamePlaceholder = oldResults.filter(
      (test) =>
        test.name.toLowerCase() === searchByName.toLowerCase() &&
        test.familyName.toLowerCase() === searchByFamilyName.toLowerCase()
    );

    if (
      (namePlaceholder.length === 0 && familyNamePlaceholder.length === 0) ||
      (namePlaceholder.length === 0 && searchByName.length !== 0) ||
      (familyNamePlaceholder.length === 0 && searchByFamilyName.length !== 0) ||
      (nameAndFamilyNamePlaceholder.length === 0 &&
        searchByName.length !== 0 &&
        searchByFamilyName.length !== 0)
    ) {
      handleFailedSearch();
      setSearched(true);
    } else if (nameAndFamilyNamePlaceholder.length !== 0) {
      newResults = nameAndFamilyNamePlaceholder;
      setSearchResult(newResults);
    } else if (familyNamePlaceholder.length !== 0) {
      newResults = familyNamePlaceholder;
      setSearchResult(newResults);
    } else {
      newResults = namePlaceholder;
      setSearchResult(newResults);
    }
  };

  const setSearchResult = (newResults) => {
    setTestResults(newResults);
    setSearchSuccess(true);
    setSearchByFamilyName("");
    setSearchByName("");
    handleSuccesfulSearch();
  };

  const handleSuccesfulSearch = () => {
    toast.success("Suche Erfolgreich!", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "colored",
      limit: 3,
    });
    setToastQueue(toastQueue + 1);
  };

  const handleFailedSearch = () => {
    toast.error("Kein Treffer!", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "colored",
      limit: 3,
    });
    setToastQueue(toastQueue + 1);
  };

  //eslint-disable-next-line
  useEffect(() => {
    if (searched) {
      setSearched(false);
    }
    if (searchSuccess) {
      setDisplayedTestsMax(16);
      setDisplayedTestsMin(0);
      setPageNumber(1);
      setIsSorted(true);
      setSearchModal(false);
      setSearchSuccess(false);
    }
    if (toastQueue >= 3) {
      toast.clearWaitingQueue();
    }
  });

  return (
    <React.Fragment>
      <LoadingModal loading={loading} />
      <ToastContainer
        limit={3}
        className={css.toast}
        closeOnClick={true}
      ></ToastContainer>
      <SearchByIdModal
        searchModal={searchModal}
        onHandleSearchModal={handleSearchModal}
        onHandleName={handleNameInput}
        onHandleFamilyName={handleFamilyNameInput}
        search={search}
      />
      <DeleteWarningModal
        onHandleWarningState={handleWarningState}
        warningState={warningState}
        delete={deleteAcknowledge}
      />
      <CreateLinkModal
        linkState={linkState}
        onHandleLinkState={handleLinkState}
        createdLink={createdLink}
        handleGenerateNewLink={generateNewLink}
      />
      <div className={css.Container}>
        <div className={css.Header}>
          <div className={css.Logo} />
          <div className={css.Knöpfe}>
            <Button
              style={css.Knopf}
              name="Link Erstellen"
              event={generateLink}
            />
            <IoLogOutSharp className={css.LogOut} onClick={logout} />
          </div>
        </div>

        <div className={css.List}>
          <div className={css.Navigation}>
            <ListHeader
              sortedBy={sortedByDate}
              isSorted={isSorted}
              name={"Datum"}
              style={css.listHeader}
              sortedDesc={descending}
              onSorted={hanldeOnSorted}
              event={resultsSortedByDate}
              handleSortedDesc={onHandleSortedDesc}
            />
            <ListHeader
              sortedBy={sortedByName}
              isSorted={isSorted}
              name={"Vorname"}
              style={css.listHeader}
              sortedDesc={descending}
              onSorted={hanldeOnSorted}
              event={resultsSortedByName}
              handleSortedDesc={onHandleSortedDesc}
            />
            <ListHeader
              sortedBy={sortedByFamilyName}
              isSorted={isSorted}
              name={"Nachname"}
              style={css.listHeader}
              sortedDesc={descending}
              onSorted={hanldeOnSorted}
              event={resultsSortedByFamilyName}
              handleSortedDesc={onHandleSortedDesc}
            />
            <div className={css.Evaluation}>Auswertung</div>
            <ImSearch className={css.SearchIcon} onClick={handleSearchModal} />
            <GoX className={css.removeFilter} onClick={handleResetFilter} />
          </div>
          <div className={css.TrueList}>
            <CompletedTestBarList
              onSorted={hanldeOnSorted}
              isSorted={isSorted}
              numberOfTestsTo={displayedTestsMax}
              numberOfTestsFrom={displayedTestsMin}
              onHandlePdf={createPdf}
              onHandleDelete={deleteRequest}
              results={testResults}
            />
          </div>
          <div className={css.arrow}>
            <Button style={css.button} name={"<"} event={lastPageOfTest} />
            <div className={css.pageNumber}>{"Seite: " + pageNumber}</div>
            <Button style={css.button} event={nextPageOfTest} name={">"} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AdminPage;
